import { Component } from '@angular/core';

@Component({
    selector: 'evc-admin',
    template: `
        <router-outlet></router-outlet>
    `,
    styleUrl: './admin.component.scss'
})
export class AdminComponent {

}
